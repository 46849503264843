<template>
    <div class="login">
        <div class="login-form">
            <div class="top">
                <img src="../../assets/images/course/login_text.png"><br>
                <img src="../../assets/images/course/login_pic.png">
            </div>
            <div class="form">
                <input type="number" placeholder="请输入手机号" v-model="phone">
                <button @click="login">立即免费领取</button>
            </div>
            <div class="foot">
                <img src="../../assets/images/course/login_foot.png">
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                phone: ''
            }
        },
        methods: {
            login() {
                if (this.phone == "") {
                    this.$msgBox.show({
                        title: '',
                        content: '<div class="mb40">请输入手机号</div>',
                        confirmBtnText: '好的',
                        isShowCancelBtn: false,
                        confirm() {}
                    })
                    return false;
                } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone)) {
                    this.$msgBox.show({
                        title: '',
                        content: '<div class="mb40">请输入正确的手机号码</div>',
                        confirmBtnText: '好的',
                        isShowCancelBtn: false,
                        confirm() {}
                    })
                    return false;
                } else {
                    this.$ajax.send({
                        url: "/course/user/login",
                        data: {
                            phone: this.phone,
                            parent_id: this.$route.query.parent_id
                        },
                        type: "post",
                        success: data => {
                            this.require = true;
                            if (!!data && data.code == "1001") {
                                this.$msgBox.show({
                                    title: '温馨提示',
                                    content: '<span class="f36">领取成功了，快去下载<br>“海螺姑娘”APP观看吧</span><br><span class="f26">*在应用商店搜索“海螺姑娘”即可下载哦</span>',
                                    confirmBtnText: '好的',
                                    isShowCancelBtn: false,
                                    confirm() {}
                                })
                            } else if (data.code == "1002") {
                                this.$msgBox.show({
                                    title: '温馨提示',
                                    content: '<span class="f36">您已领取，快去下载<br>“海螺姑娘”APP观看吧</span><br><span class="f26">*在应用商店搜索“海螺姑娘”即可下载哦</span>',
                                    confirmBtnText: '好的',
                                    isShowCancelBtn: false,
                                    confirm() {}
                                })
                            } else {
                                this.$alert.error(data.message)
                            }
                        }
                    })
                }

            }
        }
    }
</script>
<style lang="scss" scoped>
    .login {
        position: absolute;
        width: 750px;
        top: 0;
        left: calc(50% - 375px);
        bottom: 0;
        background: url('../../assets/images/course/bj.png') no-repeat;
        background-size: cover;
        background-attachment: fixed;
        overflow-y: auto;
        .login-form{
            padding: 160px 0 80px 0;
        }
        .top {
            text-align: center;

            img {
                width: 656px;

                &:first-child {
                    width: 635px;
                    margin-bottom: 35px;
                }
            }
        }

        .foot {
            text-align: center;

            img {
                width: 686px;
            }
        }

        .form {
            width: 686px;
            height: 256px;
            background: rgba($color: #F58B3E, $alpha: 0.1);
            border-radius: 20px;
            padding: 24px 24px 0 24px;
            margin: 32px auto 40px auto;

            input {
                width: 100%;
                height: 88px;
                background: #FFFFFF;
                border-radius: 12px;
                padding: 23px 0;
                text-align: center;
                font-size: 30px;
                line-height: 42px;
                border: none;
                margin-bottom: 32px;
            }

            button {
                background: #F58B3E;
                border-radius: 12px;
                height: 88px;
                width: 100%;
                line-height: 88px;
                font-size: 30px;
                color: #fff;
                text-align: center;
                border: none;
            }
        }
    }
</style>